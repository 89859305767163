import { mapInitResponse } from "./Init/RemoteConfig";
import { mapFormMaxLeaseLoanResponse } from "./MaxLeaseLoan/RemoteConfig";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import { RemoteConfig, RemoteScope } from "./RemoteTypes";
import { mapFormStandAloneResponse } from "./StandAlone/RemoteConfig";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K> } = {
    [RemoteScope.INIT]: {
        scope: RemoteScope.INIT,
        method: "GET",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.INIT));
            d(remoteClearError(RemoteScope.INIT));
        },
        paramsMapper: (_s, ctx) => ({
            channel: ctx.channel,
        }),
        pathMapper: (_s, ctx) => {
            if (ctx.route === "max-lease") {
                return "/maxLease";
            } else if (ctx.route === "max-loan") {
                return "/maxLoan";
            } else if (ctx.route === "financial-lease") {
                return "/financialLease";
            } else {
                return "";
            }
        },
        resMapper: mapInitResponse,
    },
    [RemoteScope.MAX_LEASE_POST]: {
        scope: RemoteScope.MAX_LEASE_POST,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MAX_LEASE_POST));
            d(remoteClearError(RemoteScope.MAX_LEASE_POST));
        },
        paramsMapper: (_s, ctx) => ({
            channel: ctx.channel ?? "",
        }),
        pathMapper: (_s, _ctx) => "/maxLease",
        bodyMapper: (_s, ctx) => ctx.form,
        resMapper: mapFormMaxLeaseLoanResponse,
    },
    [RemoteScope.MAX_LOAN_POST]: {
        scope: RemoteScope.MAX_LOAN_POST,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MAX_LOAN_POST));
            d(remoteClearError(RemoteScope.MAX_LOAN_POST));
        },
        paramsMapper: (_s, ctx) => ({
            channel: ctx.channel ?? "",
        }),
        pathMapper: (_s, _ctx) => "/maxLoan",
        bodyMapper: (_s, ctx) => ctx.form,
        resMapper: mapFormMaxLeaseLoanResponse,
    },
    [RemoteScope.FINANCIAL_LEASE_POST]: {
        scope: RemoteScope.FINANCIAL_LEASE_POST,
        method: "POST",
        pathMapper: (_s, _ctx) => "/financialLease",
        // pathMapper: (_s, _ctx) => "/financial-lease", // for connecting to mockoon
        paramsMapper: (_s, ctx) => ({
            channel: ctx.params.channel ?? "",
        }),
        bodyMapper: (_s, ctx) => {
            const { channel, ...filteredParamsWithoutChannel } = ctx.params;
            if(ctx.isInit) {
                const request = {
                    ...filteredParamsWithoutChannel,
                    financeType: ctx.form.financeType ?? filteredParamsWithoutChannel.financeType,
                    financeTypeToggle: filteredParamsWithoutChannel.financeType === null ? true : false,
                    readOnlyPriceIncl: filteredParamsWithoutChannel.objectPriceInclVATInclBPM === undefined
                        ? false : true,
                    readOnlyPriceExcl: filteredParamsWithoutChannel.objectPriceExclVATExclBPM === undefined
                        ? false : true,
                };
                console.log(channel);
                return (request);
            } else {
                const { channel,
                    financeType,
                    objectPriceExclVATExclBPM,
                    objectPriceInclVATInclBPM,
                    ...filteredParamsWithoutChannelAndFinanceType } = ctx.params;
                console.log(channel, objectPriceExclVATExclBPM, objectPriceInclVATInclBPM);
                return ({
                    ...ctx.form,
                    ...filteredParamsWithoutChannelAndFinanceType,
                    financeTypeToggle: financeType === null ? true : false,
                    readOnlyPriceIncl: filteredParamsWithoutChannel.objectPriceInclVATInclBPM === undefined
                        ? false : true,
                    readOnlyPriceExcl: filteredParamsWithoutChannel.objectPriceExclVATExclBPM === undefined
                        ? false : true,
                });
            }
        },
        resMapper: mapFormStandAloneResponse,
    },
};
