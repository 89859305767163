/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormField } from "../../../07-organisms/Form/Field";
import { Field, FieldType } from "../../../../../store/Init/Types";
import { FormMaxLeaseLoanField, MaxLeaseLoanValidations } from "../../../../../store/MaxLeaseLoan/Types";
import { Required } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Required";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { mapFieldsForChange, setValidationPerField, validateFieldForChange } from "../../../../utils/Calc";
import debounce from "lodash.debounce";
import { FinancialLeaseValidations, FormFinancialLeaseObject, FormFinancialLeaseResponse } from "../../../../../store/StandAlone/Types";
import { FLParams } from "../../../../../store/RemoteTypes";
import { ThemePalette } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme/_Types";
import { RangeSlider } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/RangeSlider";
import { formatOptions } from "../../../../utils/FormatDisplays";

interface Props {
    result?: FormFinancialLeaseResponse;
    onChangeFormFields: (body: FormFinancialLeaseObject, params: FLParams, init?: boolean) => void;
    params: FLParams;
    loading: boolean;
}

/**
 *
 * @param props
 */
export const StandAloneForm: React.FC<Props> = ({ onChangeFormFields, result, params, loading }) => {
    const [store, setStore] = React.useState<{
        fields:  FormMaxLeaseLoanField[];
        validations:  {[key: string]: any};
        remoteResult?: FormFinancialLeaseResponse;
    }>({
        fields: [],
        validations: {},
        remoteResult: undefined,
    });

    const formfieldsMapped: FormMaxLeaseLoanField[] = result?.formSchema?.fields ? Object.entries(result?.formSchema?.fields).map(f => {
        const min = f[1].min === null ? store.fields.find(s => s.id === f[0])?.field.min : f[1].min;
        const max = f[1].max === null ? store.fields.find(s => s.id === f[0])?.field.max : f[1].max;
        return ({
            id: f[0], // key
            field: {
                ...f[1],
                min: min,
                max: max,
                value: store.fields.find(s => s.id === f[0])?.field.active ? store.fields.find(s => s.id === f[0])?.field.value : f[1].value === null ? (min ?? 0) : f[1].value,
                show: f[1].dependencies ? false : true,
            }, // field object
        });
    }) : [];

    const initialValidations = formfieldsMapped?.reduce((acc, field) => {
        setValidationPerField({
            validations: acc as unknown as MaxLeaseLoanValidations,
            field: {
                value: field.field.value,
                type: field.field.fieldType,
                label: field.field.title,
                min: field.field.min,
                max: field.field.max,
                readonly: field.field.readonly,
            },
            fieldId: field.id,
        });
        return acc;
    }, {} as { [key: string]: any });

    React.useEffect(() => {
        if (result !== undefined && store.remoteResult !== result) {
            setStore({
                fields: formfieldsMapped,
                validations: initialValidations,
                remoteResult: result,
            });
        }
    }, [formfieldsMapped]);

    const updatedValues = (
        fields: FormMaxLeaseLoanField[],
        fieldId: string,
        field: {value: Field["value"]; type: Field["fieldType"]; label: Field["title"]; min?: Field["min"]; max?: Field["max"]; readonly?: Field["readonly"]}): FormMaxLeaseLoanField[] =>
        fields?.map(f => {
            const dependentValues = f.field.dependencies && Object.values(f.field.dependencies);

            if(fieldId === f.field.id) {
                return {
                    id: f.id,
                    field: {
                        ...f.field,
                        value: field.value,
                        active: true,
                    },
                };
            } else {
                if (fieldId === "financeType") {
                    return undefined as unknown as FormMaxLeaseLoanField;
                }
                return {
                    id: f.id,
                    field: {
                        ...f.field,
                        show: (f.field.dependencies && fieldId in f.field.dependencies
                            ? (dependentValues?.flat().includes(field.value) ? true : false) : f.field.show),
                        value: (f.field.dependencies && fieldId in f.field.dependencies
                            ? (dependentValues?.flat().includes(field.value) ? f.field.value : undefined) : f.field.value),
                        active: false,
                    },
                };
            }
        }).filter(f => f !== undefined);

    const onChange = (
        fieldId: string,
        field: {
            value: Field["value"];
            type: Field["fieldType"];
            label: Field["title"];
            min?: Field["min"];
            max?: Field["max"];
            step?: Field["step"];
            readonly?: Field["readonly"];
        }
    ) => {
        setStore({
            ...store,
            fields: updatedValues(store.fields, fieldId, field),
            validations: validateFieldForChange(store.fields, store.validations ?? {}, fieldId, field),
        });
    };

    const debouncedApiCall = React.useRef(
        debounce((store: {
            fields:  FormMaxLeaseLoanField[];
            validations:  {[key: string]: any};
            remoteResult?: FormFinancialLeaseResponse;
        }) => {
            console.log("debounced triggered");
            const formData = mapFieldsForChange(store.fields);
            onChangeFormFields(formData, params, !!store.fields.find(f => f.id === "financeType" && f.field.active));  // API call
        }, 500)
    ).current;

    const isDisabled = () => {
        const activeFieldId = store.fields?.find(f => f.field.required === true && f.field.show === true && f.field.active)?.id;
        return activeFieldId ? (store.validations?.[activeFieldId]?.["status"] !== ValidationStatus.SUCCESS) : false;
    };

    const doApiCall = () => {
        if(!isDisabled() &&
    (JSON.stringify(store.fields) !== JSON.stringify(formfieldsMapped) && formfieldsMapped.length > 0 && store.fields.length > 0)) {
            debouncedApiCall(store); // Trigger the debounced API call
        }
    };

    React.useEffect(() => {
        // Only trigger the API call if the form is valid
        doApiCall();
    }, [store.fields, store.validations]);

    const formatPercentage = (value: number): string => value.toFixed(2).replace(".", ",") + " %";
    const hasValidationError = Object.keys(store.validations).some(
        (v) =>
            store.validations[v as keyof FinancialLeaseValidations]?.status ===
ValidationStatus.ERROR
    );
    return (
        <>
            <Mary.organisms.Form>
                {store.fields?.map((f, i) => (
                    (f.field.fieldType === FieldType.SELECT && f.field.show && !!result?.financeTypeToggle)
                        ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-select`}><Mary.base.Grid
                            size={{ xs: 12, md: 12 }}
                        >
                            <FormField
                                popperPlacement={i === 0 || i === 1 ? "bottom" : "top"}
                                label={f.field.title}
                                title={f.field.description}
                                type={f.field.fieldType}
                                placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                required={f.field.required}
                                unsorted
                                value={f.field.value as string}
                                options={formatOptions(f.field.options)}
                                onChange={(value?: string | number) => {
                                    onChange(f.field.id, {
                                        value: value?.toString() ?? "",
                                        type: f.field.fieldType,
                                        label: f.field.title,
                                        min: f.field.min,
                                        max: f.field.max,
                                        readonly: f.field.readonly,
                                    });
                                }}
                                onBlur={() => doApiCall()}
                                validationFunction={(f.field.value !== undefined && store.validations[f.field.id])
                                    ? () => store.validations[f.field.id] : undefined} />
                        </Mary.base.Grid></Mary.base.Div> :
                        (f.field.fieldType === FieldType.CURRENCY && f.field.show)
                            ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-currency`}><Mary.base.Grid
                                size={{ xs: 12, md: 12 }}
                            >
                                <FormField
                                    popperPlacement={i === 0 || i === 1 ? "bottom" : "top"}
                                    label={f.field.title}
                                    title={f.field.description}
                                    type={f.field.fieldType}
                                    placeholder="e.g. 100"
                                    readonly={f.field.readonly}
                                    disabled={f.field.readonly}
                                    required={f.field.required}
                                    value={f.field.value as number}
                                    withPrefix
                                    withoutDecimal={f.field.title === "BTW bedrag" ? false : true}
                                    onChange={(value?: string | number) => {
                                        const numericValue = Number(value);
                                        onChange(f.field.id, {
                                            value: isNaN(numericValue) ? undefined : numericValue,
                                            type: f.field.fieldType,
                                            label: f.field.title,
                                            min: f.field.min,
                                            max: f.field.max,
                                            readonly: f.field.readonly,
                                        });
                                    }}
                                    onBlur={() => doApiCall()}
                                    validationFunction={(f.field.value !== undefined && store.validations[f.field.id])
                                        ? () => store.validations[f.field.id] : undefined} />
                            </Mary.base.Grid></Mary.base.Div>
                            : f.field.fieldType === FieldType.CURRENCY_RANGE ?
                                <Mary.base.Div
                                    className="scl-b-row" key={`${f.id}-${i}-currency-range`}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <Mary.base.Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormField
                                            popperPlacement={i === 0 || i === 1 ? "bottom" : "top"}
                                            label={f.field.title}
                                            title={f.field.description}
                                            type={f.field.fieldType}
                                            required={f.field.required}
                                            value={f.field.value as number}
                                            // min={f.field.min}
                                            // max={f.field.max}
                                            readonly={f.field.readonly}
                                            disabled={f.field.readonly}
                                            default={f.field.default}
                                            step={f.field.step}
                                            withPrefix={f.field.id === "duration" ? false : true}
                                            withoutDecimal
                                            onChange={(value?: string | number) => {
                                                onChange(f.field.id, {
                                                    value: value ? typeof value === "string"
                                                        ? parseFloat(value) : value : 0,
                                                    type: f.field.fieldType,
                                                    label: f.field.title,
                                                    min: f.field.min,
                                                    max: f.field.max,
                                                    step: f.field.step,
                                                    readonly: f.field.readonly,
                                                });
                                            }}
                                            onBlur={() => {
                                                doApiCall();
                                            }}
                                            validationFunction={(f.field.value !== undefined && store.validations[f.field.id])
                                                ? () => store.validations[f.field.id] : undefined} />
                                    </Mary.base.Grid>
                                    <Mary.base.Grid
                                        size={{ xs: 12, md: 6 }}
                                        style={{ height: "44px" }}
                                    >
                                        <div className="scl-a-range-slider-wrapper">
                                            <RangeSlider
                                                min={f.field.min}
                                                max={f.field.max}
                                                value={(f.field.value as number)}
                                                step={f.field.step}
                                                onChange={(newSliderValue: number) => {
                                                    onChange(f.field.id, {
                                                        value: newSliderValue,
                                                        type: f.field.fieldType,
                                                        label: f.field.title,
                                                        min: f.field.min,
                                                        max: f.field.max,
                                                        readonly: f.field.readonly,
                                                    });
                                                }}
                                                onMouseUp={() => doApiCall()}
                                            />
                                        </div>
                                    </Mary.base.Grid>
                                </Mary.base.Div>
                                : <React.Fragment key={`${f.id}-${i}-boolean`}></React.Fragment>
                ))}
                <div className="scl-h-text-align--right" style={{ marginBottom: "15px"}}><Required /></div>
                {(result?.errors ||
                    Object.keys(store.validations).some(
                        (v) =>
                            store.validations[v as keyof FinancialLeaseValidations]?.status ===
                            ValidationStatus.ERROR
                    )) && (
                    <Mary.base.Div className="scl-b-row">
                        <Mary.atoms.Blockquote
                            theme={{
                                paletteState: Mary.theme.ThemePaletteState.DANGER,
                            }}
                            background
                            className="scl-h-foreground--contrast-state-danger"
                            style={{ position: "relative" }}
                        >
                            <h5>
                                <Mary.atoms.Icon
                                    style={{
                                        fontSize: ".75em",
                                        verticalAlign: "middle",
                                        marginRight: "5px",
                                    }}
                                    name={"exclamation-triangle"}
                                />&nbsp;Niet alle velden zijn correct ingevuld!
                            </h5>
                            <br />
                            {hasValidationError && (
                                <>
                                    <p>Om door te gaan moet u de volgende velden (correct) invullen.</p>
                                    <ul style={{ marginBottom: "20px" }}>
                                        {Object.values(store.validations)
                                            .filter((validation: any) => validation.status === ValidationStatus.ERROR)
                                            .map((validation: any, index) => (
                                                <li key={`validation-error-${index}`}>
                                                    <Mary.atoms.Icon
                                                        style={{
                                                            fontSize: "8px",
                                                            verticalAlign: "middle",
                                                            marginRight: "5px",
                                                            marginLeft: "10px",
                                                        }}
                                                        name={"circle"}
                                                    />
                                                    {validation.title}
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}
                            {result?.errors && (
                                <>
                                    <p>We kunnen geen berekening maken op basis van de ingevoerde waarden.</p>
                                    <ul>
                                        {Object.values(result.errors).map((error, index) => (
                                            <li key={`result-error-${index}`}>
                                                <Mary.atoms.Icon
                                                    style={{
                                                        fontSize: "8px",
                                                        verticalAlign: "middle",
                                                        marginRight: "5px",
                                                        marginLeft: "10px",
                                                    }}
                                                    name={"circle"}
                                                />
                                                {error}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </Mary.atoms.Blockquote>
                    </Mary.base.Div>
                )}
            </Mary.organisms.Form>
            <Mary.base.BaseConsumer>
                {({ getTheme }) => (
                    <>
                        <Mary.base.Div
                            className="scl-o-form__summary"
                        >
                            <div style={{ marginBottom: "25px"}}>
                                <Mary.atoms.Heading level={getTheme() !== Mary.theme.Themes.MOBILIST ? Mary.atoms.Headings.H2 : Mary.atoms.Headings.H3} headingStyle={Mary.atoms.Headings.H5}>
                                    {result?.formSchema?.ratePerMonthTitle ?? ""}
                                </Mary.atoms.Heading>
                                <div
                                    style={{
                                        marginTop: "15px",
                                        height: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    {loading ?
                                        <Mary.atoms.loadingindicator.LoadingIndicator
                                            type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                            theme={{ palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY }}
                                        />
                                        : <Mary.atoms.Heading level={Mary.atoms.Headings.H1} headingStyle={Mary.atoms.Headings.H3}>
                                            {hasValidationError ? "-" : !!result?.ratePerMonth && result.ratePerMonth === 0 ? "€ 0" : result?.ratePerMonth === null ? "-" : `€ ${result?.ratePerMonth}`}
                                        </Mary.atoms.Heading>
                                    }
                                </div>
                            </div>
                            {(!hasValidationError && result?.netCreditAmount && result?.ratePerMonth && result?.grossCreditAmount && result?.interestRate && result?.effectiveAnnualInterestRate && result?.duration) && (
                                <>
                                    <Mary.base.Container
                                        theme={{
                                            palette: getTheme() !== Mary.theme.Themes.DUTCHLEASE ? ThemePalette.CONTRAST_PRIMARY : ThemePalette.CONTRAST_SECONDARY,
                                            padding: {
                                                "": { y: 3, x: 3 },
                                                "sm": { y: 4, x: 4 },
                                            },
                                        }}
                                        style={getTheme() !== Mary.theme.Themes.DUTCHLEASE ? { color: "#000"} : { color: "#fff" }}
                                    >
                                        <Mary.base.Div className="scl-b-row">
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Totaal kredietbedrag
                                                    </span>
                                                    <Mary.atoms.currencyinput.CurrencyInput amount={Number(result?.netCreditAmount)} withoutDecimal withPrefix />
                                                </div>
                                            </Mary.base.Grid>
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Debetrentevoet
                                                    </span>
                                                    <span>{formatPercentage(Number(result?.interestRate))}</span>
                                                </div>
                                            </Mary.base.Grid>
                                        </Mary.base.Div>
                                        <Mary.base.Div className="scl-b-row">
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Termijnbedrag
                                                    </span>
                                                    <Mary.atoms.currencyinput.CurrencyInput amount={Number(result?.ratePerMonth)} withoutDecimal withPrefix />
                                                </div>
                                            </Mary.base.Grid>
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Jaarlijks kostenpercentage
                                                    </span>
                                                    <span>{formatPercentage(Number(result?.effectiveAnnualInterestRate))}</span>
                                                </div>
                                            </Mary.base.Grid>
                                        </Mary.base.Div>
                                        <Mary.base.Div className="scl-b-row">
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Totaal te betalen bedrag
                                                    </span>
                                                    <Mary.atoms.currencyinput.CurrencyInput amount={Number(result?.grossCreditAmount)} withoutDecimal withPrefix />
                                                </div>
                                            </Mary.base.Grid>
                                            <Mary.base.Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <div className="scl-o-form__summary-row--wrapper">
                                                    <span style={{ fontWeight: "bold" }}>
                                                        Looptijd
                                                    </span>
                                                    <span>{`${Number(result?.duration)} maanden`}</span>
                                                </div>
                                            </Mary.base.Grid>
                                        </Mary.base.Div>
                                    </Mary.base.Container>
                                </>
                            )}
                            <Mary.base.Div style={{ marginTop: "25px" }}>
                                <Mary.organisms.RTE content={result?.formSchema?.disclaimer?.textOne} />
                                <Mary.organisms.RTE content={result?.formSchema?.disclaimer?.textTwo} />
                                <Mary.atoms.link.Link link={{
                                    href: result?.formSchema?.disclaimer?.hyperlink ?? "",
                                    target: Mary.atoms.link.LinkTarget.BLANK,
                                }}>
                                    <b style={getTheme() === Mary.theme.Themes.DUTCHLEASE ? { color: "#000" } : { color: "#fff" }}>{result?.formSchema?.disclaimer?.hyperlinkText}</b>
                                </Mary.atoms.link.Link>
                            </Mary.base.Div>
                        </Mary.base.Div>
                    </>
                )}</Mary.base.BaseConsumer>
        </>
    );
};


