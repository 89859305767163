/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormField } from "../../../07-organisms/Form/Field";
import { Field, FieldType, InitType } from "../../../../../store/Init/Types";
import { FormMaxLeaseLoanField,
    FormMaxLeaseLoanObject,
    FormMaxLeaseLoanObjectResponse, MaxLeaseLoanValidations } from "../../../../../store/MaxLeaseLoan/Types";
import { Required } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Required";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { FormGroup } from "../../../07-organisms/Form/Group";
import { mapFieldsForChange, validateFieldForChange } from "../../../../utils/Calc";
import debounce from "lodash.debounce";

interface Props {
    init: InitType;
    onChangeCallAPI: (body: FormMaxLeaseLoanObject, channel: string) => void;
    result?: FormMaxLeaseLoanObjectResponse;
    submitLoading: boolean;
}

export const Form: React.FunctionComponent<Props> = ({init, onChangeCallAPI, result, submitLoading }) => {
    const formfieldsMapped: FormMaxLeaseLoanField[] = init?.fields && Object.entries(init?.fields).map(f => ({
        id: f[0], // key
        field: {
            ...f[1],
            value: f[1].value === null ? undefined : f[1].value,
            show: f[1].dependencies ? false : true,
        }, // field object
    }));
    const [store, setStore] = React.useState({fields: [] as FormMaxLeaseLoanField[], validations: {} as {[key: string]: any}});
    const [initialized, setInitialized] = React.useState(false);
    const url = new URL(window.location.href);
    const channel = url.searchParams.get("channel");
    React.useEffect(() => {
        if (!initialized) {
            setStore({
                fields: formfieldsMapped,
                validations: {},
            });
            setInitialized(true); // Set form as initialized after initial state is set
        }
    }, [initialized, formfieldsMapped]);
    const updatedValues = (
        fields: FormMaxLeaseLoanField[],
        fieldId: string,
        field: {value: Field["value"]; type: Field["fieldType"]; label: Field["title"]}) => fields?.map(f => {
        const dependentValues = f.field.dependencies && Object.values(f.field.dependencies);
        if(fieldId === f.field.id) {
            return {
                id: f.id,
                field: {
                    ...f.field,
                    value: field.value,
                },
            };
        } else {
            return {
                id: f.id,
                field: {
                    ...f.field,
                    show: (f.field.dependencies && fieldId in f.field.dependencies
                        ? (dependentValues?.flat().includes(field.value) ? true : false) : f.field.show),
                    value: (f.field.dependencies && fieldId in f.field.dependencies
                        ? (dependentValues?.flat().includes(field.value) ? f.field.value : undefined) : f.field.value),
                },
            };
        }
    });

    const onChange = (
        fieldId: string,
        field: {
            value: Field["value"];
            type: Field["fieldType"];
            label: Field["title"];
        }) => {
        setStore({
            fields: updatedValues(store.fields, fieldId, field),
            validations: validateFieldForChange(store.fields, store.validations ?? {}, fieldId, field, !!result?.totalAmountValue || result?.totalAmountValue === 0),
        });
    };

    const debouncedApiCall = React.useRef(
        debounce((store) => {
            const formData = mapFieldsForChange(store.fields);
            onChangeCallAPI(formData, channel ?? "");  // API call
        }, 500)
    ).current;

    const isDisabled = () => {
        const requiredFields = store.fields?.filter(f => f.field.required === true && f.field.show === true);
        console.log("requiredFields:", requiredFields);
        const requiredFieldsAreValid = requiredFields?.map(r =>
            r.id in store.validations && store.validations[r.id]["status"] === ValidationStatus.SUCCESS);
        console.log("requiredFieldsAreValid:", requiredFieldsAreValid);
        return requiredFieldsAreValid?.includes(false) ? true : false;
    };

    React.useEffect(() => {
        // Only trigger the API call if the form is fully initialized and valid
        if (initialized && !isDisabled()) {
            console.log("API call triggered with debounce");
            debouncedApiCall(store); // Trigger the debounced API call
        }
    }, [store.fields, store.validations, initialized]);

    return (
        <><Mary.organisms.Form>
            {store.fields?.map((f, i) => (
                (f.field.fieldType === FieldType.CURRENCY && f.field.show)
                    ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-currency`}><Mary.base.Grid
                        size={{ xs: 12, md: 12 }}
                    >
                        <FormField
                            popperPlacement={i === 0 || i === 1 ? "bottom" : "top"}
                            label={f.field.title}
                            title={f.field.description}
                            type={f.field.fieldType}
                            placeholder="e.g. 100"
                            required={f.field.required}
                            value={f.field.value as number}
                            withPrefix
                            withoutDecimal
                            onChange={(value?: string | number) => {
                                onChange(f.field.id, {
                                    value: value ? typeof value === "string"
                                        ? parseFloat(value) : value : 0,
                                    type: f.field.fieldType,
                                    label: f.field.title,
                                });
                            }}
                            validationFunction={(store.validations[f.field.id])
                                ? () => store.validations[f.field.id] : undefined} />
                    </Mary.base.Grid></Mary.base.Div>
                    : (f.field.fieldType === FieldType.SELECT && f.field.show)
                        ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-select`}><Mary.base.Grid
                            size={{ xs: 12, md: 12 }}
                        >
                            <FormField
                                popperPlacement={i === 0 || i === 1 ? "bottom" : "top"}
                                label={f.field.title}
                                title={f.field.description}
                                type={f.field.fieldType}
                                required={f.field.required}
                                placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                unsorted
                                value={f.field.value as string}
                                options={f.field.options}
                                onChange={(value?: string | number) => {
                                    onChange(f.field.id, {
                                        value: value?.toString() ?? "",
                                        type: f.field.fieldType,
                                        label: f.field.title,
                                    });
                                }}
                                validationFunction={(store.validations[f.field.id])
                                    ? () => store.validations[f.field.id] : undefined} />
                        </Mary.base.Grid></Mary.base.Div>
                        : (f.field.fieldType === FieldType.BOOLEAN && f.field.show)
                            ? <React.Fragment key={`${f.id}-${i}-boolean`}>
                                <Mary.base.Div className="scl-b-row"><Mary.base.Grid
                                    size={{ xs: 12, md: 12 }}
                                >
                                    <FormGroup key={`${f.id}-${i}-status`} isRadio
                                        className={store.validations[f.field.id]?.status === ValidationStatus.SUCCESS
                                            ? "scl-h-state--success"
                                            : store.validations[f.field.id]?.status === ValidationStatus.ERROR
                                                ? "scl-h-state--danger" : ""}>
                                        <Mary.base.Div className={f.field.description ? `scl-a-tooltip__wrapper scl-a-tooltip__wrapper--${i === 0 || i === 1 ? "bottom" : "top"}` : undefined}>
                                            <Mary.base.Div>
                                                <Mary.atoms.Label>
                                                    {f.field.title}
                                                    {f.field.required === true &&
                                                            (<span className={"scl-a-label__field--as-required"}>*</span>)}
                                                    <span className={f.field.description ? "scl-a-tooltip__trigger" : undefined}>
                                                        {f.field.description ?
                                                            <Mary.atoms.Icon name="info" className="scl-a-tooltip__target" />
                                                            : null}
                                                    </span>
                                                    {f.field.description ?
                                                        <div className="scl-a-tooltip__container">
                                                            <div className="scl-a-tooltip">
                                                                <span className={"scl-a-tooltip--text"}>{f.field.description}</span>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </Mary.atoms.Label>
                                            </Mary.base.Div>
                                        </Mary.base.Div>
                                        <Mary.base.Div className="scl-a-radio__selection">
                                            {f.field.options?.map((m, j) => (
                                                <Mary.atoms.radiobtn.RadioBtn
                                                    key={`${f.id}-${i}-${j}-radio`}
                                                    checked={f.field.value?.toString() === f.field.options?.[j].value.toString() ? true : false}
                                                    label={f.field.options?.[j].label}
                                                    onChange={(value?: string | boolean) => {
                                                        onChange(f.field.id, {
                                                            value: !!value === !!f.field.options?.[j].value,
                                                            type: f.field.fieldType,
                                                            label: f.field.title,
                                                        });
                                                    }}
                                                    name={f.field.title}
                                                    value={f.field.options?.[j].value.toString()} />
                                            ))}
                                        </Mary.base.Div>
                                        <Mary.base.BaseConsumer>
                                            {({ getTheme }) => (
                                                <div className="scl-o-form__info">
                                                    {(getTheme() !== Mary.theme.Themes.MOBILIST || store.validations[f.field.id]?.status === ValidationStatus.ERROR) &&
                                            <div className="scl-o-form__info-text">
                                                {store.validations[f.field.id]?.message}
                                            </div>}
                                                    <div className="scl-o-form__info-icon">
                                                        {store.validations[f.field.id]?.status === ValidationStatus.SUCCESS
                                                            ? <i className="fa fa-check" /> : ""}
                                                    </div>
                                                </div>)}
                                        </Mary.base.BaseConsumer>
                                    </FormGroup>
                                </Mary.base.Grid>
                                </Mary.base.Div>
                            </React.Fragment>
                            : <React.Fragment key={`${f.id}-${i}-boolean`}></React.Fragment>
            ))}
            <div className="scl-h-text-align--right" style={{ marginBottom: "15px"}}><Required /></div>
            {(Object.keys(store.validations)
                .some(v => store.validations[v as keyof MaxLeaseLoanValidations]?.status === ValidationStatus.ERROR))
                && (
                    <Mary.base.Div className="scl-b-row">
                        <Mary.atoms.Blockquote
                            theme={{
                                paletteState: Mary.theme.ThemePaletteState.DANGER,
                            }}
                            background
                            className="scl-h-foreground--contrast-state-danger"
                            style={{ position: "relative" }}>
                            <h5><Mary.atoms.Icon style={{
                                fontSize: ".75em",
                                verticalAlign: "middle",
                                marginRight: "5px",
                            }} name={"exclamation-triangle"} />&nbsp;Niet alle velden zijn correct ingevuld.</h5>
                            <p>Om verder te gaan moeten de volgende velden (goed) ingevuld zijn.</p><br />
                            <ul>
                                {Object.values(store.validations)
                                    .filter((f: any) => f["status"] === ValidationStatus.ERROR).map((k: any, index) => (
                                        <li key={`${k}-${index}-validations}`}>
                                            <Mary.atoms.Icon style={{
                                                fontSize: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "5px",
                                                marginLeft: "10px",
                                            }} name={"circle"} />&nbsp;{k["title"]}
                                        </li>
                                    )
                                    )}
                            </ul>
                        </Mary.atoms.Blockquote>
                    </Mary.base.Div>
                )}
        </Mary.organisms.Form><Mary.base.BaseConsumer>
            {({ getTheme }) => (
                <Mary.base.flex.FlexRow isFixed>
                    <Mary.base.Div
                        className="scl-o-form__summary"
                    >
                        <div style={{ marginBottom: "15px"}}>
                            <Mary.atoms.Heading level={getTheme() !== Mary.theme.Themes.MOBILIST ? Mary.atoms.Headings.H2 : Mary.atoms.Headings.H3} headingStyle={Mary.atoms.Headings.H5}>
                                {init?.summary?.totalAmountLabel}
                            </Mary.atoms.Heading>
                            <div
                                style={{
                                    marginTop: "15px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {submitLoading ?
                                    <Mary.atoms.loadingindicator.LoadingIndicator
                                        type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                        theme={{ palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY }}
                                    />
                                    : <Mary.atoms.Heading level={Mary.atoms.Headings.H1} headingStyle={Mary.atoms.Headings.H3}>
                                        {(result?.totalAmountValue == null || isDisabled()) ? "-" : result.totalAmountValue === 0 ? "€ 0" : <Mary.atoms.currencyinput.CurrencyInput amount={Number(result.totalAmountValue)} withoutDecimal withPrefix />}
                                    </Mary.atoms.Heading>
                                }
                            </div>
                        </div>
                        <Mary.organisms.RTE content={init?.summary?.disclaimer} />
                    </Mary.base.Div>
                </Mary.base.flex.FlexRow>
            )}</Mary.base.BaseConsumer></>
    );
};
