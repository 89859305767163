/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { Field, FieldType } from "../../store/Init/Types";
import { FormMaxLeaseLoanField, MaxLeaseLoanValidations } from "../../store/MaxLeaseLoan/Types";
import { hasValueValidation } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/Field.utils";

const formattedMinMax = (value: number) => {
    const catValue = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2 }).format(value);
    const formattedVal = catValue.replace(catValue[catValue.length - 1], "");
    return `${formattedVal.trim()}`;
};

export const hasRangeValidation =
    (message: string, value?: string | number, max?: number, min?: number, step?: number, title?: string) => {
        if (typeof value === "number" && typeof step === "number") {
            if ((value % step) !== 0) {
                return {
                    status: ValidationStatus.ERROR,
                    message: Mary.utils.getText("mary.07-organisms.form.field.utils.meet-step--error",
                        "{{title}} moet in stappen van ({{step}}).",
                        {step: step.toString(), title: title?.toString() ?? ""}),
                    title: title ?? "",
                };
            }
        }
        if (typeof max === "number" && typeof min === "number" && typeof value === "number") {
            if (value < min || value > max) {
                return {
                    status: ValidationStatus.ERROR,
                    message: Mary.utils.getText("mary.07-organisms.form.field.utils.meet-range--error",
                        "{{title}} moet tussen ({{min}}) en ({{max}}) zijn.",
                        {min: formattedMinMax(min), max: formattedMinMax(max), title: title?.toString() ?? ""}),
                    title: title ?? "",
                };
            }
        }
        const formattedValue = (typeof value === "number" || typeof value === "string") ? value : "";
        return {...hasValueValidation(formattedValue, message), title};
    };

export const setValidationPerField = (props: {
    validations: MaxLeaseLoanValidations;
    field: {
        value: Field["value"];
        type: Field["fieldType"];
        label: Field["title"];
        min?: Field["min"];
        max?: Field["max"];
        step?: Field["step"];
        readonly?: Field["readonly"];
    };
    fieldId: string;
}) => {
    const { field, validations, fieldId } = props;
    if(field.readonly) {
        validations[fieldId] = {
            status: ValidationStatus.DISABLED,
            message: "",
            title: field.label,
        };
        return;
    }
    if(field.type === FieldType.BOOLEAN) {
        validations[fieldId] = {
            status: field.value === true || field.value === false
                ? ValidationStatus.SUCCESS : ValidationStatus.ERROR,
            message: field.value === true || field.value === false
                ? Mary.utils.getText("app.validation.has-value-message", "", {label: field.label}) : "",
            title: field.label,
        };
    } else if (field.type === FieldType.SELECT) {
        validations[fieldId] =  {
            status: field.value ? ValidationStatus.SUCCESS : ValidationStatus.ERROR,
            message: field.value ?
                Mary.utils.getText(
                    "app.validation.has-value-message", "",
                    {label: field.label})
                : Mary.utils.getText("app.validation.has-value-error-message", "", {label: field.label}),
            title: field.label,
        };
    } else if (field.type === FieldType.CURRENCY) {
        validations[fieldId] = hasRangeValidation(
            Mary.utils.getText("app.validation.has-value-error-message", "", {label: field.label}) ?? "",
            field.value as number,
            field.max,
            field.min,
            field.step,
            field.label,
        );
    } else if (field.type === FieldType.CURRENCY_RANGE) {
        validations[fieldId] = hasRangeValidation(
            Mary.utils.getText("app.validation.has-value-error-message", "", {label: field.label}) ?? "",
            field.value as number,
            field.max,
            field.min,
            field.step,
            field.label,
        );
    }
};

export const validateFieldForChange =
    (
        fields: FormMaxLeaseLoanField[],
        validations: MaxLeaseLoanValidations,
        fieldId: string,
        field: {
            value: Field["value"];
            type: Field["fieldType"];
            label: Field["title"];
            min?: Field["min"];
            max?: Field["max"];
            readonly?: Field["readonly"];
        },
        hasPrevValueFromAPI?: boolean,
    ): MaxLeaseLoanValidations => {
        fields.filter(f => f.field.dependencies && fieldId in f.field.dependencies)
            .forEach(d => {
                if(d.field.dependencies && !(Object.values(d.field.dependencies).flat().includes(field.value))) {
                    delete validations[d.field.id];
                }
            });
        setValidationPerField({validations, field, fieldId});
        if(hasPrevValueFromAPI) {
            const fieldsWithDependencies = fields.filter(f => !!f.field.required && Object.entries(f.field.dependencies ?? {}).some(f => f[0] === fieldId && f[1]?.includes(field.value)));
            fieldsWithDependencies.forEach(f => {
                setValidationPerField({validations, field: {
                    value: f.field.value,
                    type: f.field.fieldType,
                    label: f.field.title,
                    min: f.field.min,
                    max: f.field.max,
                }, fieldId: f.id});
            });
        }
        return validations;
    };

export const mapFieldsForChange = (values: FormMaxLeaseLoanField[]) =>
    values?.reduce((acc, f) => ({ ...acc, [f.id]: f.field.value}), {}); // converting fields array to object
