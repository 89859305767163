/* eslint-disable */
import axios, { CancelTokenSource } from "axios";
import { Action } from "redux";

import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { ReducerFn, RemoteState } from "./ReduxState";
import { RemoteConfigs } from "./RemoteConfigs";
import { RemoteConfig, RemoteContexts, RemoteErrorType, RemoteScope, buildRequest } from "./RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type RemoteTriggerAction = Readonly<
    Action<ActionTypeKeys.REMOTE_TRIGGER> & {
        scope?: RemoteScope;
        token?: CancelTokenSource;
    }
>;

/**
 *
 * @param scope
 * @param ctx
 */
export const remoteTrigger =
    <T extends RemoteScope>(scope: T, ctx: RemoteContexts[T], customBaseUrl?: string): Thunk<void> => (
        async (dispatch, getState) => {
            try {
                // note: this cast is safe.
                // apparently the type analyzer is not capable of determining the
                // relation between the scope variable the the use in RemoteConfigs,
                // but we can assure (FIXME: write tests for this) that the call is
                // always validated properly on the caller's side.
                const trigger = RemoteConfigs[scope] as unknown as RemoteConfig<T>;

                if (!!trigger.onInit) {
                    trigger.onInit(dispatch, getState(), ctx);
                }

                // cancel outstanding request
                remoteCancel(trigger.scope as RemoteScope);

                // dispatch the current action
                const action: RemoteTriggerAction = {
                    type: ActionTypeKeys.REMOTE_TRIGGER,
                    scope: trigger.scope,
                    token: trigger.source,
                };
                dispatch(action);

                // console.log(getState().prop("user"));
                const request = await buildRequest(getState(), trigger, ctx);

                //
                if (!!trigger.source) {
                    request.cancelToken = trigger.source.token;
                }

                // if (!!trigger.onInit) {
                //     trigger.onInit(dispatch, getState(), ctx);
                // }

                if (customBaseUrl) {
                    request.baseURL = customBaseUrl;
                }

                // trigger the event (async), get promise
                getState().consumeProp("client", async http => {
                    // try {
                    await http.request(request)
                        .then((resp) => {
                            const mapped = trigger.resMapper(resp, getState(), ctx);
                            dispatch(remoteResponse(trigger.scope as RemoteScope, mapped));

                            if (!!trigger.onSuccess) {
                                trigger.onSuccess(dispatch, getState(), ctx);
                            }
                        }).catch(err => {
                            if (axios.isCancel(err)) {
                                return;
                            }

                            // tslint:disable-next-line: no-string-literal
                            if (err.response && (err.response.status === 409 || err.response.status === 400 || err.response.status === 403)) {
                                console.log("are we here", err, trigger.scope);
                                const mapped = trigger.resMapper(err.response, getState(), ctx);
                                dispatch(remoteResponse(trigger.scope as RemoteScope, mapped));
                                const errorObjectInit = {
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    status: err?.response?.status,
                                    data: {
                                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                        error: Mary.utils.getText(
                                            "app.remote-error", "Claude can't continue with your request."),
                                        // eslint-disable-next-line max-len, @typescript-eslint/no-unsafe-assignment
                                        message: err.response.data.error ?? Mary.utils.getText("app.remote-init-message", "Claude can't establish a connection to the API."),
                                    },
                                } as ErrorResponse;
                                dispatch(remoteError(trigger.scope, errorObjectInit));
                            } else {
                                dispatch(remoteError(trigger.scope as RemoteScope, err.response || err));
                            }

                            console.log(err);
                        });
                });
            } catch (err) {
                console.log(err);
            }
        }
    );

/**
 *
 * @param s
 * @param a
 */
export const remoteTriggerReducer:
    ReducerFn<RemoteTriggerAction> =
    (s, a) => (
        s.setProp("remotes", s.prop("remotes").set(a.scope as RemoteScope, a.token))
    );

export type RemoteResponseAction<T extends RemoteScope> = Readonly<
    Action<ActionTypeKeys.REMOTE_RESPONSE> & {
        scope?: T;
        data?: RemoteState[T];
    }
>;
export const remoteResponse = <T extends RemoteScope>(scope: T, data: RemoteState[T]): RemoteResponseAction<T> => ({
    type: ActionTypeKeys.REMOTE_RESPONSE,
    scope,
    data,
});

export const remoteResponseReducer: ReducerFn<RemoteResponseAction<RemoteScope>> =
    (state, action) => {

        if (!action.scope) {
            console.warn("remoteErrorReducer: action does not have a scope", action);
            return state;
        }
        const scope = action.scope;

        return state.updateWith({
            remotes: state.mapProp("remotes", remote => remote.delete(scope)),
            remote: state.prop("remote").setProp(scope, action.data),
        });
    };

export interface ErrorResponse {
    status: number;
    data: {
        error?: string;
        message?: string;
    };
}

export type RemoteErrorAction = Readonly<
    Action<ActionTypeKeys.REMOTE_ERROR> & {
        scope?: RemoteScope;
        reason?: ErrorResponse | Error;
    }
>;

/**
 * FIXME remote error should clear the loading state of the scope, and show a
 * modal on the global error level.
 */
export const remoteError =
    (scope: RemoteScope, reason: ErrorResponse | Error): RemoteErrorAction => ({
        type: ActionTypeKeys.REMOTE_ERROR,
        scope: scope,
        reason: reason,
    });

export const remoteErrorReducer: ReducerFn<RemoteErrorAction> = (state, action) => {
    console.error("[remote error]", action.reason);
    // FIXME display this error as a modal
    if (!action.scope) {
        console.warn("remoteErrorReducer: action does not have a scope", action);
        return state;
    }
    const scope = action.scope;

    return state.updateWith({
        remotes: state.mapProp("remotes", remote => remote.delete(scope)),
        remote: state.prop("remote").setProp(scope, undefined),
        remoteErrors: state.prop("remoteErrors").set(scope, getRemoteErrorMessage(action.reason)),
    });
};

const getRemoteErrorMessage = (reason?: ErrorResponse | Error) => {
    const remoteErrorMessage = {
        message: "",
        type: RemoteErrorType.UNKNOWN_ERROR,
    };

    if (reason instanceof Error) {
        remoteErrorMessage.message = reason.toString();
        remoteErrorMessage.type = RemoteErrorType.DEFAULT;
    } else if (!!reason) {
        if (reason.data.error) {
            if (reason.status === 409 || reason.status === 400 || reason.status === 500) {
                remoteErrorMessage.message = reason.data.message ?? "";
                remoteErrorMessage.type = RemoteErrorType.VALIDATION;
            } else {
                remoteErrorMessage.message = `${remoteErrorMessage.message}
                    ${Mary.utils.getText("app.store.getremoteerrormessage.status",
        "Status:") ?? ""} ${reason.status} \n`;
                remoteErrorMessage.message = `${remoteErrorMessage.message}
                    ${Mary.utils.getText("app.store.getremoteerrormessage.error",
        "Error:") ?? ""} ${reason.data.error}\n
                    ${Mary.utils.getText("mary.09-views.03-modals.notification.message-label",
        "Message:") ?? ""} ${reason.data.message ? reason.data.message : ""}\n`;
                remoteErrorMessage.type = RemoteErrorType.SYSTEM_ERROR;
            }
        }
    }

    if (remoteErrorMessage.type === RemoteErrorType.UNKNOWN_ERROR) {
        remoteErrorMessage.message = remoteErrorMessage.message + "This is odd, not even an error object' - by Claude.";
    }
    return remoteErrorMessage;
};

export type RemoteClearErrorAction = Readonly<
    Action<ActionTypeKeys.REMOTE_CLEAR_ERROR> & {
        scope?: RemoteScope;
    }>;

export const remoteClearError =
    (scope: RemoteScope): RemoteClearErrorAction => ({
        type: ActionTypeKeys.REMOTE_CLEAR_ERROR,
        scope: scope,
    });

export const remoteClearErrorReducer:
    ReducerFn<RemoteClearErrorAction> =
    (state, action) => {

        if (!action.scope) {
            console.warn("remoteClearErrorReducer: action does not have a scope", action);
            return state;
        }
        const scope = action.scope;

        return state.setProp(
            "remoteErrors",
            state.prop("remoteErrors").delete(scope),
        );
    };

export type RemoteCancelAction = Readonly<Action<ActionTypeKeys.REMOTE_CANCEL> & { scope?: RemoteScope }>;

export const remoteCancel = (scope: RemoteScope): Thunk<void> => (
    (dispatch, getState) => {
        const cancelToken = getState().prop("remotes").get(scope);
        if (cancelToken) {
            cancelToken.cancel("remote operation canceled");
        }

        const action: RemoteCancelAction = {
            type: ActionTypeKeys.REMOTE_CANCEL,
            scope: scope,
        };
        dispatch(action);
    }
);

export const remoteCancelReducer: ReducerFn<RemoteCancelAction> =
    (state, action) => {
        if (!action.scope) {
            console.warn("remoteCancelReducer: action does not have a scope", action);
            return state;
        }
        const scope = action.scope;

        return state.updateWith({
            remotes: state.mapProp("remotes", remote => remote.delete(scope)),
            remote: state.prop("remote").setProp(scope, undefined),
        });
    };

export type RemoteClearResponseAction = Readonly<
    Action<ActionTypeKeys.REMOTE_CLEAR_RESPONSE> & {
        scope?: RemoteScope;
    }>;

export const remoteClearResponse =
    (scope: RemoteScope): RemoteClearResponseAction => ({
        type: ActionTypeKeys.REMOTE_CLEAR_RESPONSE,
        scope: scope,
    });

export const remoteClearResponseReducer:
    ReducerFn<RemoteClearResponseAction> =
    (state, action) => {

        if (!action.scope) {
            console.warn("remoteClearResponseReducer: action does not have a scope", action);
            return state;
        }
        const scope = action.scope;

        return state.setProp(
            "remote",
            state.prop("remote").setProp(
                scope,
                undefined,
            ),
        );
    };
