/* eslint-disable max-len */
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 * Formats an array of select options by capitalizing the first letter of each label
 *
 * @param {Mary.atoms.SelectOption[]} [options] - The array of select options to format
 * @returns {Mary.atoms.SelectOption[] | undefined} The formatted options array with capitalized labels, or undefined if no options provided
 *
 * @example
 * const options = [
 *     { value: 1, label: "hello" },
 *     { value: 2, label: "world" }
 * ];
 *
 * formatOptions(options);
 *
 * Returns:
 *
 * [
 *     { value: 1, label: "Hello" },
 *     { value: 2, label: "World" }
 * ]
 */
export const formatOptions = (options?:  Mary.atoms.SelectOption[]) => {
    if (!options) {
        return undefined;
    }

    return options.map(option => ({
        ...option,
        label: option.label
            ? option.label.charAt(0).toUpperCase() + option.label.slice(1)
            : undefined,
    }));
};
