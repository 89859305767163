import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from "axios";
import { DispatchFunc } from "./ActionTypes";
import { ReduxState, RemoteState } from "./ReduxState";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormMaxLeaseLoanObject } from "./MaxLeaseLoan/Types";
import { FormFinancialLeaseObject } from "./StandAlone/Types";

export const RESULTS_LIMIT = 50;

export enum RemoteErrorType {
    DEFAULT = "DEFAULT",
    VALIDATION = "VALIDATION",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type MethodType = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export interface HeadersType {
    [key: string]: string;
}

export interface RemoteConfig<T extends RemoteScope> {
    scope: T;
    identifier?: string;
    pathMapper: string | ((state: ReduxState, ctx: RemoteContexts[T]) => string);
    responseMapper?: (resp: AxiosResponse<any>, state: ReduxState, ctx: RemoteContexts[T]) => Mary.utils.Opt<{}>;
    method?: MethodType;
    bodyMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {} | undefined;
    source?: CancelTokenSource;
    paramsMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {};
    resMapper: (resp: AxiosResponse, s: ReduxState, ctx: RemoteContexts[T]) => RemoteState[T];
    onSuccess?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onInit?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    headers?: HeadersType;
    dontIncludeAuthHeader?: boolean;
    responseType?: ResponseType;
}

// TODO: Remove if not used
export interface RequestObject {
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface RefObject {
    id: number;
}

export const hasValue = (value?: string | number | boolean | RefObject) => !!value || value === 0 || value === false;

export const hasRequiredFields =
    <T, K extends keyof T>(obj: T, fields: K[]) =>
        fields.every(k => hasValue(obj[k] as unknown as string | number | boolean | RefObject));

/**
 *
 */
export type RemoteStatusValues = Readonly<{
    Statuses: Array<{
        Label: string;
        Name: string;
    }>;
}>;

/**
 *
 */
export enum RemoteScope {
    INIT = "INIT",
    MAX_LEASE_POST = "MAX_LEASE_POST",
    MAX_LOAN_POST = "MAX_LOAN_POST",
    FINANCIAL_LEASE_POST = "FINANCIAL_LEASE_POST",
}

export interface DynamicResultsContext {
    skip: number;
    path: string;
    parameters?: {
        [key: string]: string;
    };
}

export enum FinanceType {
    HK_PG = "HK-PG",
    FL_ZG = "FL-ZG",
    FL_GRL = "FL-GRL",
    FL_GRZ = "FL-GRZ",
}

export enum FLObjectCategory {
    N = "N",
    G = "G",
}

export enum FLObjectBrand {
    AUDI = "AUDI",
    CUPRA = "CUPRA",
    SEAT = "SEAT",
    SKODA = "SKODA",
    VOLKSWAGEN = "VOLKSWAGEN",
    VOLKSWAGEN_BEDRIJSWAGEN = "VOLKSWAGEN_BEDRIJSWAGEN",
    PORSCHE = "PORSCHE",
    MULTIBRAND_VAG = "MULTIBRAND_VAG",
    MULTIBRAND_NON_VAG = "MULTIBRAND_Non_VAG",
    MULTIBRAND_NON_VAG_Dealer = "MULTIBRAND_NON_VAG_Dealer"
}

export interface FLParams {
    channel: string;
    objectPriceInclVATInclBPM?: number;
    objectPriceExclVATExclBPM?: number;
    financeType?: FinanceType;
    objectCategory?: FLObjectCategory;
    objectBrand?: FLObjectBrand;
    objectModel?: string;
    objectTrim?: string;
    objectProductionYear?: number;
}
/**
 *
 */
export interface RemoteContexts {
    [RemoteScope.INIT]: {
        route: string;
        channel: string;
    };
    [RemoteScope.MAX_LEASE_POST]: {
        form: FormMaxLeaseLoanObject;
        channel: string;
    };
    [RemoteScope.MAX_LOAN_POST]: {
        form: FormMaxLeaseLoanObject;
        channel: string;
    };
    [RemoteScope.FINANCIAL_LEASE_POST]: {
        form: FormFinancialLeaseObject;
        params: FLParams;
        isInit?: boolean;
    };
}

/**
 * FIXME user auth headers should be included as part of the state's cached
 * AxiosInstance.
 */
export const buildRequest =
    async <T extends RemoteScope>(
        state: ReduxState,
        trigger: RemoteConfig<T>,
        ctx: RemoteContexts[T],
        // eslint-disable-next-line @typescript-eslint/require-await
    ): Promise<AxiosRequestConfig> => {
        let headers: HeadersType = {
            "x-api-key": process.env.REACT_APP_API_KEY || "",
        };

        if (trigger.headers) {
            headers = { ...headers, ...trigger.headers };
        }

        return {
            method: trigger.method || "GET",
            url: `${typeof trigger.pathMapper === "string"
                ? trigger.pathMapper : trigger.pathMapper(state, ctx)}`,
            // headers: state.mapProp("mary", u => u.map(authHeader).getOpt()),
            headers,
            responseType: trigger.responseType,
            params: !trigger.paramsMapper ? undefined : trigger.paramsMapper(state, ctx),
            data: !trigger.bodyMapper ? undefined : trigger.bodyMapper(state, ctx),
        };
    };
