import { SelectOption } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";

export enum FieldType {
    CURRENCY = "currency",
    BOOLEAN = "boolean",
    SELECT = "select",
    CURRENCY_RANGE = "currencyWithRange"
}

export interface Field {
    id: string;
    title: string;
    description: string;
    fieldType: FieldType;
    required: boolean;
    value?: string | number | boolean;
    options?: SelectOption[];
    dependencies?: {
        [key: string]: any;
    };
    active?: boolean;
    show?: boolean;
    min?: number;
    max?: number;
    step?: number;
    default?: number | string;
    readonly?: boolean;
}

export interface InitType {
    image?: string;
    title: string;
    description: string;
    fields: {
        [key: string]: Field;
    };
    summary: {
        totalAmountLabel: string;
        disclaimer: string;
        totalAmountValue: null;
    };
    disclaimer?: {
        textOne: string;
        textTwo: string;
        hyperlink: string;
        hyperlinkText: string;
    };
    buttonTitle: string;
}


