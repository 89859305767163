/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { FormFinancialLeaseResponse } from "./Types";

export const mapFormStandAloneResponse =
   (resp: AxiosResponse): FormFinancialLeaseResponse => ({
       statusCode: resp?.status,
       ...resp.data as FormFinancialLeaseResponse,
   });


